import React from 'react';
import { useTranslation } from 'next-i18next';
import parse from 'html-react-parser';
import classNames from 'classnames';
import { RemainingTimerContainer, RemainingTimerDateText } from '@components/_new_components/Common/Timer/RemainingTimer.styles';
import useTimer from '@hooks/useTimer';

interface Props {
  remainingTime: Date;
  isPromotion?: boolean;
}

export default function RemainingTimer({ remainingTime, isPromotion, ...rest }: Props) {
  const { t, i18n } = useTranslation(['product']);
  const { diff, format, setEndDate } = useTimer({ defaultEndDate: remainingTime, options: { includeDate: true } });

  React.useEffect(() => {
    if (remainingTime) setEndDate(remainingTime);
  }, [remainingTime]);

  if (diff === 0) {
    return null;
  }

  return (
    <RemainingTimerContainer {...rest}>
      <RemainingTimerDateText className={classNames({ unified: true, isPromotion, lang: i18n.language })}>{parse(format(t('brand:sale.remainingTime')))}</RemainingTimerDateText>
    </RemainingTimerContainer>
  );
}
