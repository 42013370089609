import React from 'react';
import { useTranslation } from 'next-i18next';
import { Container, Extension, Remainder, Spacer } from '@components/_new_components/Common/ProductCard/Extensions/Extensions.styles';
import { ProductOutput } from '../../../../../src/generated/graphql';

/**
 * cardCount: 최대로 표시할 확장자 개수입니다.
 * showRemainderUnit: 나머지 개수 단위 표시 여부입니다.
 */
type Props = {
  productId: ProductOutput['id'];
  extensions: ProductOutput['extensions'];
  cardCount?: number;
  showRemainderUnit?: boolean;
};

export default function Extensions({ productId, extensions, cardCount = 5, showRemainderUnit = true }: Props) {
  const {
    i18n: { language },
  } = useTranslation();

  const renderExtensions = [];
  for (let i = 0; i < extensions.length; i += 1) {
    const extension = extensions[i];
    if (i < cardCount) {
      const extensionToDisplay = extension.name.slice(1); // remove leading dots (EX: .skp -> skp)
      renderExtensions.push(
        <React.Fragment key={`${productId}_${extensionToDisplay}`}>
          <Extension>{extensionToDisplay}</Extension>
          {i !== extensions.length - 1 && i !== cardCount - 1 && <Spacer>{'·'}</Spacer> /* don't render spacers after last extension or 6th extension */}
        </React.Fragment>,
      );
    } else {
      renderExtensions.push(<Remainder key={`${productId}_remainder`}>{`+${extensions.length - i + 1}${language === 'ko' && showRemainderUnit ? '개' : ''}`}</Remainder>);
      break;
    }
  }

  return <Container>{renderExtensions}</Container>;
}
