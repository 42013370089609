export default function AconOnlyIcon({ width = 57, height = 16, className }: { width?: number; height?: number; className?: string }) {
  return (
    <div style={{ minWidth: `${width}px` }} className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 57 16" fill="none">
        <rect x="0.800049" width="56" height="16" rx="8" fill="#4E4EFF" />
        <path
          d="M36.3787 11.3498C35.9176 11.3498 35.5285 11.25 35.2113 11.0504C34.8993 10.8508 34.6882 10.559 34.5781 10.1748C34.4694 9.78561 34.4904 9.3216 34.6411 8.78274C34.8533 8.02436 35.1828 7.35579 35.6297 6.77702C36.0818 6.19825 36.5979 5.7517 37.178 5.43737C37.7633 5.12304 38.3564 4.96588 38.9574 4.96588C39.4185 4.96588 39.8051 5.06566 40.1171 5.26524C40.4343 5.46481 40.6473 5.75919 40.756 6.14836C40.8661 6.53254 40.8458 6.99406 40.695 7.53291C40.4829 8.29129 40.1507 8.95987 39.6987 9.53863C39.2518 10.1174 38.7357 10.5639 38.1504 10.8783C37.5703 11.1926 36.9797 11.3498 36.3787 11.3498ZM36.7035 10.4667C37.1076 10.4667 37.496 10.3469 37.8687 10.1074C38.2466 9.86793 38.5777 9.53614 38.8622 9.11204C39.148 8.68296 39.3663 8.19899 39.517 7.66014C39.6831 7.0664 39.6793 6.61736 39.5054 6.31301C39.3328 6.00367 39.0419 5.849 38.6327 5.849C38.2337 5.849 37.8479 5.96874 37.4752 6.20823C37.104 6.44273 36.7728 6.77452 36.4818 7.20361C36.1922 7.62771 35.9713 8.11167 35.8191 8.65552C35.653 9.24925 35.6562 9.70079 35.8287 10.0101C36.0026 10.3145 36.2942 10.4667 36.7035 10.4667Z"
          fill="white"
        />
        <path
          d="M40.6191 11.3498C40.4223 11.3498 40.3103 11.25 40.2832 11.0504C40.2614 10.8508 40.3118 10.5315 40.4347 10.0925C40.6161 9.44384 40.8844 8.82765 41.2394 8.24389C41.3265 8.0992 41.4309 7.99442 41.5527 7.92956C41.681 7.85971 41.8411 7.82479 42.0328 7.82479C42.1364 7.82479 42.2054 7.83726 42.2399 7.86221C42.2744 7.88715 42.2818 7.93455 42.2623 8.0044C42.2399 8.08423 42.1508 8.26385 41.9949 8.54325C41.8873 8.74283 41.797 8.91746 41.724 9.06714C41.6511 9.21682 41.5735 9.40143 41.4914 9.62096C41.7824 9.19187 42.0718 8.84262 42.3596 8.57319C42.6474 8.30376 42.9084 8.11167 43.1425 7.99692C43.3819 7.88216 43.587 7.82479 43.758 7.82479C44.0948 7.82479 44.2178 7.98694 44.1271 8.31125C44.0726 8.50583 43.9172 8.85758 43.6609 9.3665C43.441 9.80058 43.3115 10.0875 43.2724 10.2272C43.2166 10.4267 43.2638 10.5265 43.414 10.5265C43.5176 10.5265 43.6562 10.4667 43.8295 10.3469C44.0095 10.2222 44.257 10.0226 44.5721 9.74819C44.6538 9.67834 44.7336 9.64341 44.8113 9.64341C44.8786 9.64341 44.9221 9.67335 44.9416 9.73322C44.9663 9.79309 44.964 9.87542 44.9346 9.98019C44.8788 10.1798 44.7863 10.3344 44.6572 10.4442C44.3604 10.7086 44.0588 10.9257 43.7523 11.0953C43.4509 11.265 43.1371 11.3498 42.8107 11.3498C42.5465 11.3498 42.3672 11.2774 42.273 11.1327C42.1802 10.9831 42.1728 10.7685 42.251 10.4891C42.2901 10.3494 42.3962 10.0999 42.5692 9.7407C42.7245 9.42637 42.8189 9.20934 42.8524 9.08959C42.8747 9.00976 42.8574 8.96985 42.8004 8.96985C42.733 8.96985 42.6135 9.05467 42.4417 9.2243C42.2764 9.38895 42.0907 9.60849 41.8844 9.8829C41.6833 10.1573 41.5013 10.4467 41.3384 10.7511C41.2158 10.9856 41.1038 11.1452 41.0024 11.23C40.9075 11.3099 40.7797 11.3498 40.6191 11.3498Z"
          fill="white"
        />
        <path
          d="M47.3455 9.64341C47.4128 9.64341 47.4563 9.67335 47.4758 9.73322C47.5005 9.79309 47.4982 9.87542 47.4689 9.98019C47.413 10.1798 47.3205 10.3344 47.1914 10.4442C46.8946 10.7086 46.5904 10.9257 46.2787 11.0953C45.9722 11.265 45.6532 11.3498 45.3216 11.3498C44.8657 11.3498 44.5821 11.1502 44.471 10.7511C44.3651 10.3519 44.4008 9.8355 44.578 9.20185C44.7483 8.59315 45.0227 7.89963 45.401 7.12128C45.7846 6.34294 46.2125 5.67437 46.6849 5.11556C47.1625 4.55675 47.6215 4.27734 48.0618 4.27734C48.3105 4.27734 48.4734 4.3896 48.5505 4.61413C48.6341 4.83366 48.618 5.15048 48.5022 5.5646C48.336 6.15834 47.9724 6.84687 47.4113 7.6302C46.8503 8.41353 46.1695 9.18938 45.3691 9.95774C45.3481 10.1623 45.3665 10.3095 45.4243 10.3993C45.4835 10.4841 45.5804 10.5265 45.7151 10.5265C45.9275 10.5265 46.1301 10.4692 46.3228 10.3544C46.5169 10.2347 46.7781 10.0326 47.1063 9.74819C47.1881 9.67834 47.2678 9.64341 47.3455 9.64341ZM47.6836 5.01827C47.5644 5.01827 47.3718 5.22532 47.1057 5.63944C46.8396 6.05356 46.5637 6.56746 46.278 7.18116C45.9923 7.79485 45.7654 8.38359 45.5974 8.94739C46.1285 8.36364 46.5846 7.77988 46.9655 7.19613C47.3478 6.60738 47.6066 6.07102 47.742 5.58705C47.8481 5.20786 47.8286 5.01827 47.6836 5.01827Z"
          fill="white"
        />
        <path
          d="M50.1637 7.82479C50.2829 7.82479 50.3616 7.83975 50.3999 7.86969C50.4381 7.89963 50.4475 7.94952 50.4279 8.01937C50.3944 8.13912 50.2077 8.56571 49.8678 9.29914C49.5518 10.0026 49.3653 10.4193 49.3083 10.549C48.7579 11.8312 48.2096 12.8466 47.6635 13.595C47.1173 14.3434 46.5645 14.7176 46.0049 14.7176C45.7407 14.7176 45.548 14.6378 45.4269 14.4781C45.3044 14.3234 45.2787 14.1189 45.3499 13.8644C45.4155 13.6299 45.5369 13.3904 45.7141 13.1459C45.8965 12.9015 46.1826 12.6196 46.5724 12.3002C46.966 11.9859 47.4973 11.6242 48.1661 11.2151L48.2376 11.0429C48.3255 10.8583 48.4409 10.5939 48.5839 10.2496C48.3797 10.6089 48.1579 10.8833 47.9183 11.0729C47.6802 11.2575 47.4575 11.3498 47.2502 11.3498C47.0171 11.3498 46.854 11.2475 46.761 11.0429C46.6746 10.8334 46.6746 10.5739 46.7611 10.2646C46.8658 9.89038 46.9874 9.54861 47.1257 9.23927C47.2655 8.92494 47.4438 8.59315 47.6606 8.24389C47.7543 8.09421 47.8569 7.98694 47.9683 7.92208C48.0797 7.85722 48.2364 7.82479 48.4385 7.82479C48.5525 7.82479 48.6253 7.84225 48.657 7.87717C48.6938 7.9121 48.7025 7.96449 48.6829 8.03434C48.6718 8.07425 48.6056 8.20897 48.4844 8.43848C48.3809 8.62308 48.2927 8.79023 48.2198 8.93991C48.1211 9.14447 48.0297 9.34155 47.9455 9.53115C47.8628 9.71576 47.8047 9.86793 47.7712 9.98768C47.7181 10.1773 47.746 10.2721 47.8548 10.2721C47.9325 10.2721 48.0493 10.1972 48.2052 10.0475C48.3662 9.89787 48.5541 9.67085 48.7687 9.3665C48.9886 9.06215 49.2228 8.68795 49.4714 8.24389C49.5547 8.09421 49.6469 7.98694 49.7479 7.92208C49.8542 7.85722 49.9928 7.82479 50.1637 7.82479ZM46.2935 13.9916C46.423 13.9916 46.6086 13.847 46.8502 13.5576C47.0917 13.2682 47.387 12.7867 47.7361 12.1131C47.2464 12.4375 46.8739 12.7318 46.6186 12.9963C46.3671 13.2657 46.2134 13.5002 46.1576 13.6998C46.1338 13.7846 46.1298 13.8544 46.1456 13.9093C46.1665 13.9642 46.2158 13.9916 46.2935 13.9916Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.63455 4.76562H12.1951L13.7015 11.3389L11.6473 11.3389L11.5104 10.5173H9.31927L9.18233 11.3389H7.12817L8.63455 4.76562ZM9.95834 6.68284H10.8713L11.2593 9.01088H9.57033L9.95834 6.68284Z"
          fill="white"
        />
        <path
          d="M16.9881 4.76562C15.1729 4.76562 13.7015 6.2371 13.7015 8.05227C13.7015 9.86743 15.1729 11.3389 16.9881 11.3389H18.6314V9.14782L17.1112 9.35512C16.3217 9.46278 15.6187 8.84909 15.6187 8.05227C15.6187 7.25546 16.3217 6.64176 17.1112 6.74942L18.6314 6.95672V4.76562H16.9881Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3289 4.76562C20.5894 4.76562 19.1792 6.1758 19.1792 7.91533V8.18922C19.1792 9.92875 20.5894 11.3389 22.3289 11.3389C24.0684 11.3389 25.4786 9.92875 25.4786 8.18922V7.91533C25.4786 6.1758 24.0684 4.76562 22.3289 4.76562ZM22.3289 6.68284C21.6482 6.68284 21.0964 7.23464 21.0964 7.91533V8.18922C21.0964 8.8699 21.6482 9.42171 22.3289 9.42171C23.0096 9.42171 23.5614 8.8699 23.5614 8.18922V7.91533C23.5614 7.23464 23.0096 6.68284 22.3289 6.68284Z"
          fill="white"
        />
        <path d="M28.0805 11.3389H26.0264V4.76562H27.8067L29.9451 8.30509L29.7239 4.76562H31.778V11.3389H29.9978L27.8593 7.79945L28.0805 11.3389Z" fill="white" />
      </svg>
    </div>
  );
}
