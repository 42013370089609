import React from 'react';
import { throttleWrapper } from '@util/eventHandling';
import { useClip } from '@hooks';
import { ScrapWrapper } from '@components/_new_components/Common/ProductCard/Scrap/Scrap.styles';
import ScrapIcon from '../../../../../src/assets/icons/productCard/ScrapIcon';

interface Props {
  productId: number;
  onClickScrap?: () => void;
  className?: string;
}

export default function Scrap({ productId, onClickScrap, className }: Props) {
  const { isClip, handleClip: onClip } = useClip(productId);

  // 스크랩 클릭 시 상태 변경
  const handleScrapWrapperClick = throttleWrapper(async (e) => {
    e.preventDefault();
    await onClip();

    onClickScrap && onClickScrap();
  });

  return (
    <ScrapWrapper onClick={handleScrapWrapperClick} className={className}>
      <ScrapIcon isActive={isClip} />
    </ScrapWrapper>
  );
}
