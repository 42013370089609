import React from 'react';
import {
  ProductCardBrandAndScrapWrapper,
  ProductCardBrandLink,
  ProductCardBrandText,
  ProductCardBrandWrapper,
} from '@components/_new_components/Common/ProductCard/brand/ProductCardBrand.styles';
import Scrap from '@components/_new_components/Common/ProductCard/Scrap/Scrap';
import { ProductCardBrandPropsType } from '@components/_new_components/Common/ProductCard/brand/ProductCardBrand.types';
import AconOnlyIcon from '../../../../../src/assets/icons/productCard/badge/AconOnlyIcon';
import { Brand_Output_Brand_Vip_Type } from '../../../../../src/generated/graphql';
import VipIcon from '../../../../../src/assets/icons/productCard/badge/VipIcon';

function ProductCardBrand({ id, brandInfo, onClickBrand, onClickScrap }: ProductCardBrandPropsType) {
  return (
    <ProductCardBrandAndScrapWrapper>
      <ProductCardBrandWrapper>
        <ProductCardBrandLink href={`/brand/${brandInfo.code}`} onClick={onClickBrand} prefetch={false}>
          <ProductCardBrandText>{brandInfo.name}</ProductCardBrandText>
        </ProductCardBrandLink>
        {brandInfo?.isAcon3DExclusive && <AconOnlyIcon />}
        {brandInfo?.vipInfo === Brand_Output_Brand_Vip_Type.Vip && <VipIcon />}
      </ProductCardBrandWrapper>

      <Scrap productId={id} onClickScrap={onClickScrap} />
    </ProductCardBrandAndScrapWrapper>
  );
}

export default ProductCardBrand;
