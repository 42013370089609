import styled from '@emotion/styled';

export const Container = styled.div`
  margin-top: 4px;
  ${(props) => props.theme.breakpoints.down('st_600')} {
    margin-top: 2px;
  }
  display: flex;
  width: 100%;
  height: 20px;
  overflow-x: hidden;
  color: #a2a2a4;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
`;

export const Extension = styled.div`
  padding: 4px 2px;
`;

export const Spacer = styled.div`
  padding: 5px 2px 4px 2px;
`;

export const Remainder = styled.div`
  padding: 4px 4px;
`;
