import React from 'react';
import styled from '@emotion/styled';

const NewBadgeWrapper = styled.div`
  filter: drop-shadow(0 1.5px 6px rgba(0, 0, 0, 0.06));

  & > div.small {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    & > div.large {
      display: none;
    }

    & > div.small {
      display: block;
    }
  }
`;

export default function NewBadgeIcon() {
  return (
    <NewBadgeWrapper>
      <div className={'large'}>
        <svg width="41" height="22" viewBox="0 0 41 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="41" height="22" rx="3" fill="white" />
          <path
            d="M25.6938 15.3516L23.1426 6.51367H25.4375L26.7925 12.373H26.8657L28.4038 6.51367H30.2959L31.8462 12.3853H31.9072L33.2744 6.51367H35.5693L33.0059 15.3516H31.0039L29.3926 9.90723H29.3193L27.708 15.3516H25.6938Z"
            fill="black"
          />
          <path d="M16.1602 15.3516V6.51367H22.2759V8.21045H18.2354V10.0781H21.9585V11.7749H18.2354V13.6548H22.2881V15.3516H16.1602Z" fill="black" />
          <path d="M14.8174 6.51367V15.3516H13.0474L9.4585 10.1636H9.39746V15.3516H7.32227V6.51367H9.1167L12.6689 11.7017H12.7422V6.51367H14.8174Z" fill="black" />
        </svg>
      </div>

      <div className={'small'}>
        <svg width="35" height="18" viewBox="0 0 35 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="35" height="18" rx="3" fill="white" />
          <path
            d="M21.8777 12.8516L19.6836 5.25098H21.6572L22.8225 10.29H22.8855L24.2083 5.25098H25.8354L27.1687 10.3005H27.2212L28.397 5.25098H30.3706L28.166 12.8516H26.4443L25.0586 8.16943H24.9956L23.6099 12.8516H21.8777Z"
            fill="black"
          />
          <path d="M13.6797 12.8516V5.25098H18.9392V6.71021H15.4644V8.31641H18.6663V9.77563H15.4644V11.3923H18.9497V12.8516H13.6797Z" fill="black" />
          <path d="M12.5239 5.25098V12.8516H11.0017L7.91528 8.38989H7.86279V12.8516H6.07812V5.25098H7.62134L10.6763 9.71265H10.7393V5.25098H12.5239Z" fill="black" />
        </svg>
      </div>
    </NewBadgeWrapper>
  );
}
