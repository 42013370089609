import React from 'react';
import { useTranslation } from 'next-i18next';
import { PriceTextPropsType } from '@components/_new_components/Common/Text/PriceText.types';
import { AmountText, PriceTextWrapper, PriceUnitText } from '@components/_new_components/Common/Text/PriceText.styles';
import useExchange from '@hooks/useExchange';
import { LanguageCode } from '../../../../src/constants';

export default function PriceText({
  amount,
  type = 'display',
  isPoint = false,
  isAbbreviated = false,
  isShowCurrencyUnit = true,
  toFixed = null,
  render,
  ...rest
}: PriceTextPropsType) {
  const { i18n } = useTranslation();
  const { isNotSupportedCurrencyForPayment, isReady, ex } = useExchange(i18n.language as LanguageCode);
  if (!isReady) return <div />;

  const exObj = ex(amount)[type];
  const { unit, position } =
    isPoint && !isNotSupportedCurrencyForPayment
      ? {
          unit: 'P',
          position: 'right',
        }
      : exObj.currencyUnit(isAbbreviated);

  const currencyUnitText = render?.priceUnitText ? render.priceUnitText(unit) : <PriceUnitText>{unit}</PriceUnitText>;
  const amountText = render?.amountText ? render.amountText(exObj.fixedPrice(toFixed)) : <AmountText>{exObj.fixedPrice(toFixed)}</AmountText>;

  return (
    <PriceTextWrapper {...rest}>
      {isShowCurrencyUnit && position === 'left' && currencyUnitText}
      {amountText}
      {isShowCurrencyUnit && position === 'right' && currencyUnitText}
    </PriceTextWrapper>
  );
}
