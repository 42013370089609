import React from 'react';
import styled from '@emotion/styled';

interface Props {
  isActive: boolean;
}

const ScrapIconWrapper = styled.div`
  & > .small {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    & > .large {
      display: none;
    }

    & > .small {
      display: block;
    }
  }
`;

export default function ScrapIcon({ isActive }: Props) {
  return (
    <ScrapIconWrapper>
      {isActive ? (
        <>
          <div className={'large'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
              <path
                d="M11.3 12.6667C11.3 11.7462 12.0462 11 12.9667 11H24.6333C25.5538 11 26.3 11.7462 26.3 12.6667V25.5843C26.3 26.4869 25.3727 27.092 24.5466 26.7285L19.4712 24.4953C19.0435 24.3072 18.5565 24.3072 18.1288 24.4953L13.0534 26.7285C12.2273 27.092 11.3 26.4869 11.3 25.5843V12.6667Z"
                fill="#FF5C00"
              />
            </svg>
          </div>
          <div className={'small'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
              <path
                d="M8.5 10.6667C8.5 9.74619 9.24619 9 10.1667 9H21.8333C22.7538 9 23.5 9.74619 23.5 10.6667V23.5843C23.5 24.4869 22.5727 25.092 21.7466 24.7285L16.6712 22.4953C16.2435 22.3072 15.7565 22.3072 15.3288 22.4953L10.2534 24.7285C9.42729 25.092 8.5 24.4869 8.5 23.5843V10.6667Z"
                fill="#FF5C00"
              />
            </svg>
          </div>
        </>
      ) : (
        <>
          <div className={'large'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
              <path
                d="M11.7 12.6667C11.7 12.0776 12.1775 11.6 12.7666 11.6H24.4333C25.0224 11.6 25.5 12.0776 25.5 12.6667V25.5843C25.5 26.0537 25.0178 26.3683 24.5882 26.1793L19.5128 23.9462C18.9312 23.6902 18.2688 23.6902 17.6871 23.9462L12.6118 26.1793C12.1822 26.3683 11.7 26.0537 11.7 25.5843V12.6667Z"
                stroke="#D2D2D3"
                strokeWidth="1.2"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={'small'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
              <path
                d="M9.1 10.6667C9.1 10.0776 9.57756 9.6 10.1667 9.6H21.8333C22.4224 9.6 22.9 10.0776 22.9 10.6667V23.5843C22.9 24.0537 22.4178 24.3683 21.9882 24.1793L16.9129 21.9462C16.3312 21.6902 15.6688 21.6902 15.0871 21.9462L10.0118 24.1793C9.58219 24.3683 9.1 24.0537 9.1 23.5843V10.6667Z"
                stroke="#D2D2D3"
                strokeWidth="1.2"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </>
      )}
    </ScrapIconWrapper>
  );
}
