import styled from '@emotion/styled';

export const NewBadge = styled.div`
  display: flex;
  padding: 4.352px 4.35px 4.648px 6.65px;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);

  color: #000;
  font-size: 12.5px;
  font-weight: 800;
  line-height: 100%;
`;
