export default function ProductCardEyeIcon({ size = 16, color = '#BCBCBE' }) {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.787766 8C1.41477 4.58651 4.40542 2 8.00021 2C11.5949 2 14.5856 4.58651 15.2126 8C14.5856 11.4135 11.5949 14 8.00021 14C4.40542 14 1.41477 11.4135 0.787766 8ZM8.00021 11.3333C9.84114 11.3333 11.3335 9.84093 11.3335 8C11.3335 6.15905 9.84114 4.66667 8.00021 4.66667C6.15923 4.66667 4.66685 6.15905 4.66685 8C4.66685 9.84093 6.15923 11.3333 8.00021 11.3333ZM8.00021 10C6.89561 10 6.00018 9.1046 6.00018 8C6.00018 6.8954 6.89561 6 8.00021 6C9.10474 6 10.0002 6.8954 10.0002 8C10.0002 9.1046 9.10474 10 8.00021 10Z"
        fill={color}
      />
    </svg>
  );
}
