import React from 'react';

export default function VipIcon({ width = 20, height = 16, className }: { width?: number; height?: number; className?: string }) {
  return (
    <div style={{ minWidth: `${width}px` }} className={className}>
      <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="16" rx="8" fill="url(#paint0_linear_4090_112166)" />
        <path
          d="M5.82701 5.18367C5.74066 5.10348 5.61499 5.08209 5.50696 5.1292C5.39894 5.1763 5.3291 5.28295 5.3291 5.40079V10.5004C5.3291 10.664 5.46176 10.7967 5.6254 10.7967H14.3754C14.539 10.7967 14.6717 10.664 14.6717 10.5004V5.40079C14.6717 5.28295 14.6019 5.1763 14.4938 5.1292C14.3858 5.08209 14.2601 5.10348 14.1738 5.18367L12.1245 7.08661L10.2089 5.19023C10.0934 5.07592 9.90741 5.07592 9.79194 5.19023L7.87634 7.08661L5.82701 5.18367Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_4090_112166" x1="-1.42857" y1="2.28571" x2="24.1813" y2="5.15154" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFE584" />
            <stop offset="0.348958" stopColor="#F6AE34" />
            <stop offset="0.65625" stopColor="#F1CF4D" />
            <stop offset="0.916667" stopColor="#F08700" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
