import { Stack } from '@mui/material';
import { Typography } from 'carpenstreet-designsystem';
import { Fragment, useMemo } from 'react';
import { numberWithCommas } from '@lib/index';
import ProductCardEyeIcon from '../../icon/product/ProductCardEyeIcon';
import ProductCardStarsIcon from '../../icon/product/ProductCardStarsIcon';
import { IReviewAndStarProps } from './ReviewAndStar.types';
import { VISIBLE_RATING, VISIBLE_REVIEW_MAX } from './ReviewAndStar.constans';

export default function ReviewAndStar({ viewCount, review = [] }: IReviewAndStarProps) {
  const rating = useMemo(() => (review && review.length > 0 ? review.reduce((acc, curr) => acc + curr.rating, 0) / review.length : 0), [review]);

  if (viewCount < 1) return <Fragment />;

  return (
    <Stack direction="row" gap="7px" mt="4px" p="2px">
      <Stack direction="row" gap="2px" justifyContent="center" alignItems="center">
        <ProductCardEyeIcon />
        <Typography variant="typography/label/small/medium" color="color/gray/300">
          {numberWithCommas(viewCount)}
        </Typography>
      </Stack>
      {review.length && rating > VISIBLE_RATING ? (
        <Stack direction="row" gap="2px" justifyContent="center" alignItems="center">
          <ProductCardStarsIcon />
          <Typography variant="typography/label/small/medium" color="color/gray/300">
            {rating.toFixed(1)}
          </Typography>
          {review.length >= VISIBLE_REVIEW_MAX && (
            <Typography variant="typography/label/small/medium" color="color/gray/300">
              ({review.length})
            </Typography>
          )}
        </Stack>
      ) : null}
    </Stack>
  );
}
