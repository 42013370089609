import styled from '@emotion/styled';

export const PriceTextWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

export const PriceUnitText = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  //line-height: 24px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 14px;
    //line-height: 150%;
  }
`;

export const AmountText = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 17px;
    line-height: 24px;
  }
`;
