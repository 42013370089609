import styled from '@emotion/styled';
import Link from 'next/link';
import Scrap from '@components/_new_components/Common/ProductCard/Scrap/Scrap';

export const ProductCardBrandAndScrapWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProductCardBrandWrapper = styled.div`
  padding: 12px 0 6px 0;
  display: flex;
  gap: 3px;
  max-width: calc(100% - 35px);

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 10px 0 4px 0;
  }
`;

export const ProductCardBrandLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
`;

export const ProductCardBrandText = styled.div`
  color: #a2a2a4;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const ResponsiveScrap = styled(Scrap)`
  ${(props) => props.theme.breakpoints.down('st_600')} {
    height: 28px;
    overflow: hidden;
    margin-top: -3px;
  }
`;
