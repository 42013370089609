import styled from '@emotion/styled';
import Link from 'next/link';
import { NewBadge } from '@components/_new_components/Common/ProductCard/Badge/Badge.styles';
import PriceText from '@components/_new_components/Common/Text/PriceText';
import RemainingTimer from '@components/_new_components/Common/Timer/RemainingTimer';
import { RemainingTimerDateText, RemainingTimerLabelText } from '@components/_new_components/Common/Timer/RemainingTimer.styles';
import { AmountText, PriceUnitText } from '@components/_new_components/Common/Text/PriceText.styles';

export const ProductCardContainer = styled.div`
  padding-bottom: 14px;
  min-width: 279.5px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding-bottom: 12px;
  }
`;

export const ProductCardImageAbsolute = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.4s;
`;

export const ProductCardImageLink = styled(Link)``;

export const ProductCardImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 350/215;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  & ${ProductCardImageAbsolute}.sub {
    opacity: 0;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover ${ProductCardImageAbsolute}.sub {
      opacity: 1;
    }

    &:hover ${ProductCardImageAbsolute}.main {
      opacity: 0;
    }
  }
`;

export const ProductCardInnerBorder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

export const ProductCardTitleText = styled.div`
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 2px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    margin-bottom: 0;
  }
`;

export const ProductCardPriceWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const ProductCardDiscountRateText = styled.div`
  color: #ff5c00;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 17px;
  }
`;

export const ProductCardPriceText = styled(PriceText)`
  & > ${PriceUnitText}, ${AmountText} {
    ${(props) => props.theme.breakpoints.down('st_600')} {
      font-size: 17px;
      line-height: 24px;
    }
  }

  & > ${PriceUnitText} {
    font-size: 15px;
    line-height: normal;
    font-weight: 800;
    padding-bottom: 2px;

    ${(props) => props.theme.breakpoints.down('st_600')} {
      font-size: 14px;
      line-height: 150%;
      padding-bottom: 0;
    }
  }
`;

export const ProductCardFreeText = styled.div`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  line-height: 24px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 17px;
  }
`;

export const ProductCardBadgeWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 2;
  display: flex;
  gap: 6px;
`;

export const ProductCardNewBadge = styled(NewBadge)``;

export const ProductCardRemainingTimer = styled(RemainingTimer)`
  padding: 3px 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  height: 22px;

  & > ${RemainingTimerLabelText} {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }

  & > ${RemainingTimerDateText} {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
  }

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    padding: 0 5px 0 6px;
    height: 18px;

    & > ${RemainingTimerLabelText} {
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
    }

    & > ${RemainingTimerDateText} {
      padding-top: 0;
      font-size: 11px;
      line-height: 100%;
      font-weight: 600;
    }
  }
`;
