import { IS_PROD } from 'src/constants';

export const BLACK_FRIDAY_2024_EVENT_ID = IS_PROD
  ? ([101, 102, 103, 104, 105, 106, 107] as const)
  : process.env.NEXT_PUBLIC_STAGING === 'true'
  ? ([56, 57, 58, 59, 60, 61, 62] as const)
  : ([78, 79, 80, 81, 82, 83, 84] as const);

export const BLACK_FRIDAY_2024_COUPON_TO_PRICE_MAP: Record<number, number> = {
  4379: 1500,
  4385: 1500,
  4391: 1500,
  4397: 1500,
  4403: 1500,
  4409: 1500,
  4415: 1500,

  4378: 3000,
  4384: 3000,
  4390: 3000,
  4396: 3000,
  4402: 3000,
  4408: 3000,
  4414: 3000,

  4377: 4500,
  4383: 4500,
  4389: 4500,
  4395: 4500,
  4401: 4500,
  4407: 4500,
  4413: 4500,

  4376: 6000,
  4382: 6000,
  4388: 6000,
  4394: 6000,
  4400: 6000,
  4406: 6000,
  4412: 6000,

  4375: 7500,
  4381: 7500,
  4387: 7500,
  4393: 7500,
  4399: 7500,
  4405: 7500,
  4411: 7500,

  4374: 10000,
  4380: 10000,
  4386: 10000,
  4392: 10000,
  4398: 10000,
  4404: 10000,
  4410: 10000,
};

export const BLACK_FRIDAY_2024_EVENT_ID_TO_CATEGORY_CODE_MAP = {
  [BLACK_FRIDAY_2024_EVENT_ID[0]]: '372001',
  [BLACK_FRIDAY_2024_EVENT_ID[1]]: '372001',
  [BLACK_FRIDAY_2024_EVENT_ID[2]]: '372002',
  [BLACK_FRIDAY_2024_EVENT_ID[3]]: '372002',
  [BLACK_FRIDAY_2024_EVENT_ID[4]]: '372003',
  [BLACK_FRIDAY_2024_EVENT_ID[5]]: '372003',
  [BLACK_FRIDAY_2024_EVENT_ID[6]]: '372004',
};
export const BLACK_FRIDAY_2024_PRODUCT_IDS = new Set([
  1000007556, 1000003421, 1000036771, 1000016118, 1000015958, 1000016205, 1000016307, 1000010505, 1000007345, 1000001892, 1000001893, 1000003417, 1000003359, 1000017159,
  1000017160, 1000039592, 1000012321, 1000039402, 1000037424, 1000016975, 1000016974, 1000019473, 1000018615, 1000007551, 1000014908, 1000014905, 1000016593, 1000004901,
  1000017215, 1000016098, 1000017370, 1000017920, 1000017978, 1000031474, 1000033953, 1000001849, 1000001850, 1000004231, 1000038209, 1000017161, 1000009523, 1000038217,
  1000002314, 1000012971, 1000035951, 1000036207, 1000038703, 1000018014, 1000033607, 1000019402, 1000016443, 1000035440, 1000003505, 1000005387, 1000006383, 1000009460,
  1000033510, 1000035603, 1000036655, 1000034427, 1000001807, 1000019676, 1000003544, 1000003546, 1000003543, 1000003545, 1000003547, 1000032177, 1000034966, 1000036260,
  1000004394, 1000013261, 1000005346, 1000016642, 1000002081, 1000002082, 1000033630, 1000039587, 1000037730, 1000040031, 1000040032, 1000034019, 1000034057, 1000033999,
  1000034000, 1000039401, 1000012064, 1000038560, 1000032155, 1000016055, 1000018184, 1000016433, 1000035043, 1000009554, 1000010101, 1000015588, 1000037283, 1000004172,
  1000002612, 1000002378, 1000002481, 1000010277, 1000032374, 1000007167, 1000033774, 1000002981, 1000017093, 1000032004, 1000013729, 1000011386, 1000032864, 1000035713,
  1000019693, 1000018826, 1000035720, 1000002334, 1000002159, 1000015557, 1000006402, 1000033273, 1000032675, 1000007368, 1000038943, 1000035651, 1000038257, 1000017345,
  1000014828, 1000013273, 1000009750, 1000009715, 1000004020, 1000003361, 1000005012, 1000017314, 1000002172, 1000002786, 1000035606, 1000010815, 1000004233, 1000008777,
  1000011926, 1000007286, 1000036451, 1000015769, 1000011553, 1000011918, 1000011851, 1000011544, 1000034963, 1000009306, 1000009305, 1000002425, 1000015743, 1000007228,
  1000002840, 1000004764, 1000011529, 1000035491, 1000002841, 1000038150, 1000002207, 1000016836, 1000012180, 1000003429, 1000014622, 1000019553, 1000031889, 1000006408,
  1000006757, 1000018637, 1000012886, 1000012366, 1000016727, 1000012874, 1000002965, 1000035245, 1000003490, 1000036875, 1000018010, 1000012342, 1000003799, 1000032882,
  1000035630, 1000009005, 1000019401, 1000007459, 1000001790, 1000005579, 1000001791, 1000001793, 1000004388, 1000004539, 1000002690, 1000000148, 1000017622, 1000017581,
  1000017620, 1000017578, 1000017497, 1000017602, 1000017137, 1000008535, 1000035629, 1000009814, 1000036810, 1000035134, 1000035133, 1000035132, 1000001683, 1000000576,
  1000036864, 1000036846, 1000032422, 1000018607, 1000018609, 1000018610, 1000010813, 1000009824, 1000009475, 1000010061, 1000017807, 1000035715, 1000035716, 1000001699,
  1000003879, 1000004847, 1000009459, 1000035786, 1000032876, 1000032428, 1000032429, 1000032430, 1000032431, 1000032432, 1000032433, 1000005434, 1000032423, 1000036426,
  1000001946, 1000009486, 1000018670, 1000032427, 1000011500, 1000010959, 1000004442, 1000004650, 1000036068, 1000035650, 1000038995, 1000033419, 1000037425, 1000017312,
  1000012972, 1000009359, 1000007718, 1000004475, 1000031763, 1000014879, 1000011543, 1000018705, 1000015319, 1000015527, 1000004429, 1000005426, 1000038993, 1000039019,
  1000038967, 1000039020, 1000014861, 1000014966, 1000039304, 1000001716, 1000001760, 1000002173, 1000009304, 1000004214, 1000002036, 1000001560, 1000001171, 1000037585,
  1000000168, 1000000538, 1000009664, 1000014624, 1000014623, 1000013878, 1000003426, 1000003848, 1000004920, 1000033120, 1000033126, 1000033742, 1000002685, 1000006228,
  1000008513, 1000015680, 1000008042, 1000019285, 1000017482, 1000002875, 1000000223, 1000000385, 1000005137, 1000005136, 1000005135, 1000003623, 1000002329, 1000016176,
  1000001763, 1000033469, 1000018012, 1000033032, 1000017990, 1000005876, 1000039109, 1000012963, 1000019224, 1000039767, 1000039984, 1000036667, 1000033954, 1000038416,
  1000038266, 1000038299, 1000016623, 1000016621, 1000016103, 1000039123, 1000012642, 1000009704, 1000003167, 1000003890, 1000003940, 1000004980, 1000005583, 1000007346,
  1000008773, 1000011313, 1000035322, 1000008795, 1000011963, 1000035157, 1000002692, 1000000149, 1000031918, 1000032571, 1000032260, 1000034146, 1000039034, 1000000577,
  1000004065, 1000002236, 1000002237, 1000007066, 1000016930, 1000006309, 1000007868, 1000011130, 1000011850, 1000012272, 1000017793, 1000017799, 1000017806, 1000017798,
  1000017795, 1000017801, 1000017800, 1000017797, 1000038198, 1000038094, 1000006199, 1000012086, 1000031599, 1000001880, 1000001713, 1000005686, 1000004041, 1000002358,
  1000001879, 1000002078, 1000007659, 1000008205, 1000004455, 1000014897, 1000009783, 1000039668, 1000037365, 1000032924, 1000017543, 1000009888, 1000009890, 1000009892,
  1000017394, 1000002910, 1000004657, 1000011481, 1000031847, 1000015840, 1000018520, 1000011040, 1000035802, 1000035705, 1000038794, 1000034853, 1000031845, 1000015028,
  1000037049, 1000038123, 1000032068, 1000034962, 1000032969, 1000013979, 1000006381, 1000004119, 1000004196, 1000004147, 1000012624, 1000037543, 1000038060, 1000013573,
  1000001731, 1000009307, 1000033566, 1000033565, 1000033533, 1000033558, 1000014561, 1000003812, 1000003966, 1000004270, 1000004285, 1000003266, 1000005895, 1000003633,
  1000004096, 1000002161, 1000038908, 1000012713, 1000009406, 1000012714, 1000004182, 1000012970, 1000000465, 1000001547, 1000000484, 1000010944, 1000004717, 1000036222,
  1000032968, 1000011527, 1000039277, 1000034579, 1000034580, 1000018488, 1000035531, 1000018483, 1000013879, 1000005096, 1000005097, 1000005094, 1000036865, 1000004353,
  1000009447, 1000006959, 1000018152, 1000015016, 1000016956, 1000012639, 1000035801, 1000036815, 1000038409, 1000036631, 1000008026, 1000009308, 1000014994, 1000034009,
  1000036780, 1000017018, 1000019403, 1000016837, 1000032505, 1000016033, 1000015785, 1000035806, 1000035183, 1000012233, 1000033693, 1000036157, 1000005024, 1000034051,
  1000032913, 1000018880, 1000018126, 1000016551, 1000015582, 1000015402, 1000010453, 1000011628, 1000003080, 1000001647, 1000006854, 1000005555, 1000004536, 1000004048,
  1000016421, 1000034259, 1000033686, 1000039441, 1000035280, 1000012340, 1000036681, 1000039169, 1000039175, 1000039176, 1000016036, 1000038548, 1000006797, 1000000117,
  1000000118, 1000000472, 1000000575, 1000018759, 1000018470, 1000016105, 1000016108, 1000016104, 1000016106, 1000039610, 1000036778, 1000037787, 1000019474, 1000037780,
  1000023802, 1000010394, 1000038125, 1000039486, 1000037718, 1000038358, 1000036953, 1000036702, 1000004924, 1000039383, 1000039373, 1000038087, 1000036143, 1000008425,
  1000001664, 1000001897, 1000001666, 1000001663, 1000001665, 1000006016, 1000006014, 1000006015, 1000006017, 1000011328, 1000002922, 1000015486, 1000008788, 1000010170,
  1000015485, 1000005288, 1000002335, 1000006905, 1000002436, 1000003666, 1000019730, 1000002958, 1000033708, 1000002838, 1000014540, 1000001896, 1000003523, 1000018348,
  1000018312, 1000008008, 1000037870, 1000013645, 1000013646, 1000019404, 1000033710, 1000015839, 1000039425, 1000002336, 1000015560, 1000004341, 1000011299, 1000004289,
  1000004444, 1000004445, 1000011308, 1000011298, 1000004443, 1000033571, 1000038204, 1000037841, 1000015980, 1000007317, 1000017691, 1000038436, 1000004970, 1000005032,
  1000035905, 1000005530, 1000017991, 1000032946, 1000017446, 1000018727, 1000035307, 1000036387, 1000018352, 1000004040, 1000006296, 1000003397, 1000039463, 1000003951,
  1000004995, 1000014942, 1000014941, 1000014940, 1000035143, 1000038784, 1000018325, 1000008787, 1000009343, 1000037959, 1000037529, 1000037067, 1000032679, 1000015345,
  1000015340, 1000015344, 1000039479, 1000002486, 1000015775, 1000014381, 1000014384, 1000001808, 1000002177, 1000035193, 1000034530, 1000037965, 1000005656, 1000037739,
  1000001198, 1000004710, 1000004711, 1000032184, 1000033622, 1000013239, 1000012946, 1000034817, 1000037056, 1000038863, 1000032788, 1000036580, 1000006236, 1000003430,
  1000010255, 1000037829, 1000038534, 1000038535, 1000018058, 1000002601, 1000006297, 1000004688, 1000007581, 1000009722, 1000011412, 1000031432, 1000031436, 1000031437,
  1000038093, 1000031885, 1000016644, 1000034566, 1000035202, 1000019220, 1000035168, 1000037146, 1000035160, 1000032182, 1000038489, 1000012648, 1000039597, 1000036372,
  1000038105, 1000033864, 1000035626, 1000039770, 1000039709, 1000033468, 1000036819, 1000038875, 1000031824, 1000037430, 1000032747, 1000016049, 1000036155, 1000036203,
  1000036189, 1000033809, 1000039608, 1000033813, 1000030874, 1000038677, 1000038456, 1000038491, 1000038492, 1000038458, 1000038493, 1000038494, 1000037008, 1000039410,
  1000035490, 1000012577, 1000034366, 1000037841, 1000032696, 1000032684, 1000038231, 1000037665, 1000036734, 1000035419, 1000039588, 1000039604, 1000034494, 1000038844,
  1000038883, 1000038842, 1000038843, 1000038450, 1000033209, 1000033205, 1000033204, 1000039797, 1000038399, 1000038784, 1000039488, 1000032447, 1000039450, 1000038564,
  1000038563, 1000038224, 1000038499, 1000039048, 1000039043, 1000035535, 1000039170, 1000037058, 1000037032, 1000037308, 1000036779, 1000036371, 1000017297, 1000034968,
  1000018458, 1000035780, 1000032106, 1000036633, 1000032967, 1000033156, 1000036808, 1000032445, 1000034355, 1000032619, 1000033957, 1000035422, 1000039124, 1000033622,
  1000031515, 1000035515, 1000037192, 1000037854, 1000039332, 1000039162, 1000037055, 1000039858, 1000037830, 1000038550, 1000038942, 1000037725, 1000031402, 1000035291,
  1000036653, 1000039590, 1000036152, 1000038524, 1000039255, 1000036558, 1000036559, 1000036560, 1000039879, 1000039943, 1000037377, 1000037703, 1000032255, 1000036826,
  1000038328, 1000038197, 1000038196, 1000038192, 1000038100, 1000037922, 1000038193, 1000038194, 1000039250, 1000039365, 1000038191, 1000035951, 1000036207, 1000038703,
  1000033510, 1000039587, 1000035043, 1000032004, 1000039767, 1000036667, 1000032882, 1000032422, 1000039984, 1000031885, 1000036810, 1000036864, 1000039169, 1000039175,
  1000039176, 1000038548, 1000038436, 1000032788, 1000037829, 1000033507, 1000039486, 1000038358, 1000038125, 1000037718,
]);
