import styled from '@emotion/styled';

export const RemainingTimerContainer = styled.div`
  border-radius: 6px;
  background: #ff5c00;
  padding: 5px 7px 5px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RemainingTimerLabelText = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`;

export const RemainingTimerDateText = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  column-gap: 6px;
  
  &.unified:not(.isPromotion) {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    column-gap: 4px;
    ${(props) => props.theme.breakpoints.down('dt_1720')} {
      font-size: 11px;
        line-height: 1.5;    
    }
    
  }
    & > b {
        font-weight: 600;
    }
  
`;
